import type { ApiClientDynamicDataFetcher } from '@blg/api-definition';
import { type Writable, writable } from 'svelte/store';

interface DynamicDataFetcherStore extends Writable<ApiClientDynamicDataFetcher> {
	setDynamicDataFetcher(dynamicDataFetcher: ApiClientDynamicDataFetcher): void;
}

function createApiClientDynamicDataFetcher(): DynamicDataFetcherStore {
	const { subscribe, set, update } = writable<ApiClientDynamicDataFetcher>();

	return {
		subscribe,
		set,
		update,
		setDynamicDataFetcher: (dynamicDataFetcher: ApiClientDynamicDataFetcher) =>
			set(dynamicDataFetcher)
	};
}

export const dynamicDataFetcherStore = createApiClientDynamicDataFetcher();
